/* TeamCard.css */
/* .team-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.card-container {
  position: relative;
  width: 250px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: #333;
}

.curtain {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: lightblue;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  z-index: 1;
}

.card-container:hover .curtain {
  transform: translateX(0);
} */
.team-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.card-container {
  position: relative;
  width: 250px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card-container:hover {
  transform: translateY(-5px);
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 16px;
  position: relative;
  z-index: 1; /* Keeps the content above the curtain */
}

.card-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.text-content {
  margin-top: 8px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.card-desc {
  font-size: 14px;
  color: #666;
}

.curtain {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1d3f586c; /* Slight transparency for effect */
  transform: translateX(0); /* Curtain is visible initially */
  transition: transform 0.5s ease-in-out;
  z-index: 2; /* Below the card content */
}

.card-container:hover .curtain {
  transform: translateX(100%); /* Curtain slides off when hovered */
}
